






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';

@Component({})

export default class PowerDialog extends Vue {
    @Global.State('lang') lang;
    
    closePowerDialog() {
        this.$emit('close');
    }
}
